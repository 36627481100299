import * as React from "react";
import { graphql } from "gatsby";
import { Link, withPrefix } from 'gatsby';
import {
  Layout, SEO, CBreadCrumb, LWrap, CBtnList, CAccordionList } from "../../components/_index"
import { size } from "lodash";
import { setLang } from "../../utils/set-lang"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <section className="l_sect02">
        <LWrap size="small">
          <h1 className="c_headingLv2">プライバシーポリシー</h1>
          <p className="u_mbLarge">三菱地所ホテルズ＆リゾーツ株式会社（以下、当社と呼びます）では、ご利用いただくお客様個人を特定することが出来る情報（以下、個人情報と呼びます）を重要なものと認識し、情報管理関連規程を設け慎重に取扱っております。本ページでは個人情報取扱いにおける当社の基本方針を掲載しておりますのでご覧下さい。</p>
          <section className="u_mbLarge">
            <h2 className="c_headingLv3">個人情報の取扱いに関する基本事項について</h2>
            <h4 className="c_headingLv4">個人情報の取得について</h4>
            <p className="u_mb40">当社ではお客様の個人情報を業務上必要な範囲に限り、以下の方法により適切に取得させて頂きます。<br />
            ・ ご本人から直接取得: 電話、口頭、書面（電磁的記録含みます）、名刺、インターネット等（当社が経営・運営するホテルをご利用の際の入力情報、会員登録の際の入力情報等含む）<br />
            ・ ご本人から正当な権限を授権された方から取得： 利用申込者、紹介者、旅行斡旋事業者、提携先等の第三者<br />
            ・ 公表されているものから取得：　新聞、インターネット、電話帳、書籍等<br />
            ・ 当社の許諾を得て当社の商標が使用されているホテル（当社が経営・運営するホテルと併せて、以下、ロイヤルパークホテルズグループホテルと呼びます）の経営・運営にあたる事業者及び三菱地所株式会社有価証券報告書またはグループ会社一覧に記載の当社グループ会社及び関係者等（以下、当社グループ会社等と呼びます）からの取得：当該事業者のプライバシーポリシーに定められた適正な個人情報の共同利用の取得方法による
            </p>
            <h4 className="c_headingLv4">個人情報の利用目的について</h4>
            <p className="u_mb40">当社では以下の場合、お客様の個人情報を利用させて頂きます。<br />
            ・ 当社における宿泊、飲食、宴会等ホテル施設運営業務において、サービスを提供する場合<br />
            ・ 当社から施設・商品サービス等のご案内を差し上げる場合<br />
            ・ 当社のサービス向上のため、お客様にアンケートを差し上げる等、調査にご協力を頂く場合<br />
            ・ 当社の会員制度についての各種情報およびサービスの提供において使用する場合<br />
            ・ ご本人の同意を得て第三者提供を行う場合<br />
            ・ 当社及びロイヤルパークホテルズグループホテル、当社グループ会社等の事業化支援、連携において使用する場合<br />
            ・ 当社及びロイヤルパークホテルズグループホテル、当社グループ会社等の広報活動・IR活動における各種連絡・案内等に使用する場合<br />
            ・ 当社及びロイヤルパークホテルズグループホテル、当社グループ会社等の環境活動・CSR活動における各種連絡・案内、成果品・発行物の発送等運営・管理に使用する場合<br />
            ・ 当社及びロイヤルパークホテルズグループホテル、当社グループ会社等が事務局となる各種団体等の運営に使用する場合<br />
            ・ 当社に対する就職希望者採用選考活動において使用する場合<br />
            ・ その他当社の業務に付帯・関連する事項への対応または、個人情報取得時に明らかにした利用目的の範囲において使用する場合
            </p>
            <h4 className="c_headingLv4">個人情報の共同利用について</h4>
            <p className="u_mb40">当社はお客様に付加価値の高いサービスをご提供するため、厳格な管理と適切な安全対策のもと、以下のとおり個人情報の共同利用を行います。<br />
            ・ 個人情報を共同利用する者：　ロイヤルパークホテルズグループホテルの経営・運営にあたる事業者及び当社グループ会社等<br />
            ・ 共同利用の目的：<br />
            （1） ロイヤルパークホテルズグループホテルにおける宿泊、飲食、宴会等ホテル施設運営業務において、サービスを提供する場合<br />
            （2） ロイヤルパークホテルズグループホテルから施設・商品サービス等のご案内を差し上げる場合<br />
            （3） ロイヤルパークホテルズグループホテルのサービス向上のため、お客様にアンケートを差し上げる等、調査にご協力を頂く場合
            <br />
            （4） ロイヤルパークホテルズグループホテルの会員制度についての各種情報およびサービスの提供において使用する場合<br />
            （5） その他ロイヤルパークホテルズグループホテルの業務に付帯・関連する事項への対応または、個人情報取得時に明らかにした利用目的の範囲において使用する場合<br />
            ・ 共同利用する個人情報の項目： お客様の氏名、会員番号、電話・FAX番号、国籍、パスポート番号、住所、Eメールアドレス（その他これに準ずるソーシャルメディアのID等を含みます）、年齢、性別、誕生日、記念日、勤務先情報、その他ご要望、ご利用履歴等に関する要配慮情報項目<br />
            ・ 共同利用する個人情報の管理責任者：三菱地所ホテルズ＆リゾーツ株式会社
            </p>
            <h4 className="c_headingLv4">個人情報の第三者提供について</h4>
            <p className="u_mb40">当社では以下の場合を除いて、第三者に個人情報を提供または開示することはありません。<br />
            1. お客様ご本人から同意があった場合<br />
            2. 法令に基づく場合<br />
            3. 人の生命、身体又は財産といった具体的な権利利益が侵害されるおそれがあり、これを保護するために個人情報を提供する必要がある場合であって、お客様ご本人から同意を得ることが困難である場合<br />
            4. 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合<br />
            5. 国の機関等が法令の定める事務を実施する上で、民間企業等の協力を得る必要がある場合であって、協力する民間企業等（ロイヤルパークホテルズグループホテルの経営・運営にあたる事業者）が当該国の機関等に個人情報を提供することについてお客様ご本人の同意を得ることが当該事務の遂行に支障を及ぼすおそれがある場合<br />
            6. 当該第三者が学術研究機関等である場合であって、当該第三者が当該個人データを学術研究目的で取り扱う必要があるとき（当該個人データを取り扱う目的の一部が学術研究目的である場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除く。）
            </p>

            <h4 className="c_headingLv4">個人情報お問合せ窓口（所管部署）等</h4>
            <p className="u_mb20">当社の保有する個人情報の開示等のご請求手続や当社における個人情報の取扱いに関するお問い合わせ・ご意見等は、以下の窓口までご連絡ください。お問い合わせ等がご本人によるものであることを確認させて頂いたうえで、対応いたします（但し、一定の場合にはご要望に添いかねることがあります）。
            </p>
            <p className="u_mb40">三菱地所ホテルズ＆リゾーツ株式会社<br />
            個人情報お問合せ窓口<br />
            郵便番号：107-0062<br />
            所在地：東京都港区南青山1-1-1 新青山ビル東館13F</p>
            
            <h2 className="c_headingLv3">個人情報管理体制について</h2>
            <p className="u_mb40">当社では以下のとおり、個人情報の適正な管理と保護に努めます。</p>
            
            <h4 className="c_headingLv4">個人情報の保全</h4>
            <p className="u_mb40">
            当社では情報管理を推進する情報管理委員会を設置して不正なアクセスからの個人情報保護に努めております。<br />
            ・個人情報の安全確保のため、システムへの不正アクセスまたは個人情報の紛失、破壊、改ざん、漏洩などを防止するため、運用管理面・システム面の両面から安全対策を講じております。<br />
            ・個人情報が登録されたデータベースは外部からのアクセスを防御する仕組み（ファイアーウォール）で保護されたシステムに保存されます。<br />
            ・当社のホームページにてお客様とインターネットを通じて行われる個人情報の送受信はSSL（Secure Sockets Layer）による暗号化通信を採用しており、SSLに対応していないブラウザをご利用の場合は、SSLセキュアページへのアクセスや情報の入力ができない場合があります。<br />
            ・ 当社では、お客様の利便性の向上を目的としてホームページ等でCookie（クッキー）と呼ばれる情報を利用する場合があります。お客様のブラウザの設定変更によりCookie（クッキー）機能を無効にすることができますが、その結果一部のサービスをご利用いただけなくなることがあります。<br />
            ・ 当社ではウェブサイトの利用回数調査など、統計的な分析を目的としてアクセスログ情報の活用を行うことがありますが、個人を特定できる情報を含むものではありません。
            </p>
            <h4 className="c_headingLv4">社内体制</h4>
            <p className="u_mb40">・ 当社では従業員にお客様の個人情報保護の徹底を図ることを目的に必要な安全対策を実施しております。<br />
            ・ 当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託した個人情報の安全管理が図られるよう、委託先における個人情報の取扱状況の定期的な点検を行う等の必要かつ適切な監督を行っています。<br />
            ・ 当社は、外国において個人情報を取り扱うに当たっては、定期的に当該国の個人情報保護制度に関する情報を収集し把握した上で、安全管理措置を講じています。
            </p>
            <h4 className="c_headingLv4">リンク先における個人情報</h4>
            <p className="u_mb40">当社のホームページはお客様により便利に有用な情報とサービスを提供するために第三者（他の会社、団体）のホームページ（ウェブサイト）へのリンクを紹介致しております。リンク先のホームページ（ウェブサイト）にて行われる個人情報の収集、取り扱いに関しまして当社は一切の責任を負いません。</p>
            <h4 className="c_headingLv4">その他</h4>
            <p className="u_mb20">・ 当社で『プライバシーポリシー』の内容に変更の必要が生じた場合は、当社のホームページ等でその内容を告知致します。確認されていなかったことに起因するトラブルに関しては、当社は一切責任を負いかねますので予めご了承ください。<br />
            ・ ご登録頂きました情報に変更が生じた場合、削除をご希望の場合にはサービスを円滑に提供するためにもなるべく速やかにご登録されたホテルにご連絡を下さいますようお願い致します。</p>
            <p className="u_mb40">情報管理責任者<br />
            三菱地所ホテルズ＆リゾーツ株式会社<br />
            取締役社長　大島 正也
            </p>
          </section>
          <section className="u_mbLarge" wovn-ignore="true">
            <CAccordionList data={
              [
                {
                  title: "PRIVACY POLICY（GDPR）",
                  content: <>
                    <h2 className="c_headingLv3">MITSUBISHI ESTATE HOTELS & RESORTS Co., Ltd. PRIVACY POLICY (LAST UPDATED: [APRIL 1, 2022])</h2>
                    <p className="u_mbLarge">Mitsubishi Estate Hotels & Resorts Co., Ltd. (the “Company”) is aware that information that could identify an individual customer of the Company (“Personal Information”) is valuable information, and has therefore established regulations on information management in order to handle Personal Information with the utmost care. Please review the Company’s basic policy on the handling of Personal Information set forth below.</p>
                    <h3 className="c_headingLv4">Ⅰ. Collection of Personal Information</h3>
                    <p className="u_mb40">The Company collects Personal Information only to the extent appropriate and necessary to conduct the Company’s business using the methods set forth below.<br />
                    1. Direct collection from customers: Collection via telephone, orally, in writing (including electronic records), from business cards, online (including information that is input when using websites of hotels managed and operated by the Company and information input when performing member registration), and so on. The information we collect from you is likely to include.<br />
                    2. Collection from third parties duly authorized by customers: Collection from third parties such as users, potential users, referral agencies, travel trade professionals, and business partners. The information we collect from such third parties is likely to include<br />
                    3. Collection from publicly available sources: Collection from newspapers, online, telephone directories, books, and other such sources. The information we collect from such publicly available sources is likely to include　your name, telephone or fax number, nationality, address, email address (including social media IDs and the like associated with email addresses), age, gender, date of birth, anniversary date, employer information, requests, and use history.<br />
                    4. Collection from businesses engaged in the management and operation of the hotels that use the Company’s trademarks under license from the Company (referred to collectively with hotels managed and operated by the Company as “Royal Park Hotels Group Hotels”), Mitsubishi Estate Co., Ltd., its group companies listed in financial statements or on the list of group companies, and other relevant parties(“Mitsubishi Estate Group”): Personal Information will be collected by the relevant businesses and shared by means of shared use of Personal Information, as specified in the privacy policies of these businesses. The information we collect from such businesses is likely to include your name, membership registration number, telephone or fax number, nationality, passport number, address, email address (including social media IDs and the like associated with email addresses), age, gender, date of birth, anniversary date, employer information, requests, and use history.
                    </p>
                    <h3 className="c_headingLv4">Ⅱ. Purposes of Use of Personal Information</h3>
                    <p className="u_mb40">The Company uses Personal Information for the following purposes.<br />
                    1. To provide services through the operation of hotel facilities such as accommodation, dining, and banquet space.<br />
                    2. To provide information regarding its facilities, products, services, and so on.<br />
                    3. To facilitate customer surveys and the like presented by the Company intended to improve the Company’s services.<br />
                    4. To provide various information and services in relation to its membership program provided by the Company.<br />
                    5. Provision of personal information to third parties only when granted consent by the person concerned.<br />
                    6. For support and collaboration on commercialization of businesses of the Company and its group companies.<br />
                    7. For communication/notifications in corporate communication activities/IR activities.<br />
                    8. For various communication/notifications and operations/management of delivery of deliverables/publication in environmental activities/CSR activities.<br />
                    9. For operations of various organizations where the Company takes the role of administrative office.<br />
                    10. For conduct of employment application screening at the Company.<br />
                    11. To respond to other matters ancillary or related to its operations when use is within the scope of the intended use specified at the time that the Personal Information was acquired.
                    </p>
                    <h3 className="c_headingLv4">Ⅲ. Shared Use of Personal Information</h3>
                    <p className="u_mb40">In order to add high levels of value to the services provided to customers, the Company shares Personal Information as set forth below under strict controls and with appropriate security measures in place.<br />
                    1. Joint users of Personal Information: Businesses engaged in the management and operation of Royal Park Hotels Group Hotels, including guest service providers and Mitsubishi Estate Group.<br />
                    2. Purposes of Shared Use:<br />
                    (1)   When providing services through the operation of hotel facilities such as accommodation, dining, and banquet space at Royal Park Hotels Group Hotels.<br />
                    (2)   When Royal Park Hotels Group Hotels provide information regarding facilities, products, services, and so on.<br />
                    (3)   When you participate in customer surveys and the like presented by the Company intended to improve Royal Park Hotels Group Hotel services.<br />
                    (4)   When the Company provides various information and services in relation to its membership programs provided by Royal Park Hotels Group Hotels.<br />
                    (5)   When Royal Park Hotels Group Hotels respond to other matters ancillary or related to their operations when use is within the scope of the intended use specified at the time that the Personal Information was acquired.<br />
                    3. Personal Information subject to shared use: your name, membership registration number, telephone or fax number, nationality, passport number, address, email address (including social media IDs and the like associated with email addresses), age, gender, date of birth, anniversary date, employer information, requests, and use history.<br />
                    4. Administrator of Personal Information subject to shared use: Mitsubishi Estate Hotels & Resorts Co., Ltd.
                    </p>
                    <h3 className="c_headingLv4">Ⅳ. Provision of Personal Information to Third Parties</h3>
                    <p className="u_mb40">Except in the following cases, the Company shall not provide or disclose Personal Information to third parties. Subject to the requirements specified in Articles X and XI, the Company may disclose Personal Information to cloud service providers as long as such cloud service providers are precluded from using the Personal Information provided.<br />
                    1. When you consent.<br />
                    2. When the disclosure is based on laws and regulations.<br />
                    3. When there is a need to protect a human life, body or fortune, and when it is difficult to obtain your consent.<br />
                    4. When it is particularly necessary to improve public health or promote the sound development of children, and it is difficult to obtain the consent of the person.<br />
                    5. When there is a need to cooperate in regard to a central government organization or a local government, or a person entrusted by them performing affairs prescribed by laws and regulations, and when there is a possibility that obtaining your consent would interfere with the performance of the said affairs.5.When there is a need to cooperate in regard to a central government organization or a local government, or a person entrusted by them performing affairs prescribed by laws and regulations, and when there is a possibility that obtaining your consent would interfere with the performance of the said affairs.<br />
                    6. Where such third party is an academic research institution, etc., and it is deemed necessary for such third party to handle such personal data for academic research purpose (including cases where part of the purpose of handling such personal data is for academic research purpose, but excluding cases where it is deemed liable to unjustifiably infringe on individuals’ rights and interests).</p>
                    <h3 className="c_headingLv4">Ⅴ. Contact Information regarding Personal Information (Responsible Division)</h3>
                    <p className="u_mb20">For inquiries regarding procedures for requesting disclosure, etc. of personal information held by the Company and the handling of personal information by the Company, please contact the following. We will respond after confirming that the inquiry, etc. is made by the person in question.<br /></p>
                    <p className="u_mb40">Company name： Mitsubishi Estate Hotels & Resorts Co., Ltd.<br />
                    Address : Shin Aoyama Bldg. East 13F, 1-1-1, Minamiaoyama, Minato-ku, Tokyo 107-0062 JAPAN<br />
                    E-mail: privacy-data@rphs.jp</p>
                    <h3 className="c_headingLv4">Ⅵ. Protection of Personal Information</h3>
                    <p className="u_mb40">The Company has established the Information Management Committee to manage Personal Information as it strives to protect Personal Information from unauthorized access. The Information Management Committee has implemented, and will continue to oversee, the following matters.<br />- To ensure the security of Personal Information and prevent unauthorized access to systems and the loss, destruction, alteration, or leak of Personal Information, the Company has implemented security countermeasures concerning both operational management and systems.<br />
                    - Databases in which Personal Information is recorded are stored on systems protected by mechanisms (including firewalls) to prevent external access.<br />
                    - Transmission and receipt of Personal Information with you via the Internet on the Company’s website use encrypted transmissions that employ Secure Sockets Layer (SSL). In cases where you use a browser that does not support SSL, you may not be able to access SSL secure pages or to input information.</p>
                    <h3 className="c_headingLv4">Ⅶ.  Internal Systems</h3>
                    <p className="u_mb40">The Company has implemented security measures to ensure that its employees carry out comprehensive protection of your Personal Information, and to appropriately monitor outside contractors and others.</p>
                    <h3 className="c_headingLv4">Ⅷ.  Personal Information on Third-Party Linked Sites</h3>
                    <p className="u_mb40">In order to provide more useful information and services to you, the Company’s websites contain links to the websites of third parties (other companies and organizations). The Company assumes no responsibility regarding the collection and handling of Personal Information by such linked third-party websites.</p>
                    <h3 className="c_headingLv4">Ⅸ. Other</h3>
                    <p className="u_mb40">- If it becomes necessary to revise this Privacy Policy, the Company shall provide notice to that effect on its website or by other means. The Company assumes no responsibility regarding any problems that may arise as a result of failure to confirm changes to this Privacy Policy.<br />
                    - If any changes occur to information registered with the Company or if you want Personal Information to be deleted, please promptly contact the hotel that registered the information so that the services can be effectively provided.</p>
                    <h3 className="c_headingLv4">Ⅹ. Handling of Personal Information of EEA and UK residents</h3>
                    <p className="u_mb40">In addition to Articles I-IX above, the provisions in this Article X and Article XI below shall apply to the handling of Personal Information of persons in the European Economic Area (EEA and UK) that is subject to the General Data Protection Regulation (REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL). If any provision of these Articles X and XI conflicts with the provisions of Articles I-IX above, the provisions of Articles X and XI shall prevail.<br />1. Legal basis for processing: The Company will process and use Personal Information to manage its contractual relationship with you, to pursue a legitimate interest, and/or to comply with a legal obligation.<br />
                    It will be necessary for the Company to use your Personal Information to complete a booking you have made with us. For example, we will need to use information such as your contact details and payment information to provide you with the stay and/or restaurant booking you have requested and paid for.<br />
                    Alternatively, we may collect and use your Personal Information where you have given your specific consent to us doing so.<br />
                    2. Collection of access data: In addition to the information specified in Article I, we automatically collect data related to you when you visit our websites. This data that we obtain from you when you visit us (“access data”) includes access logs, web beacons (also known as pixel tags), cookies, etc. For more information about how we use your access data, please see Article XI below.<br />
                    3. Disclosure of your Personal Information to recipients： We may share your Personal Information with businesses engaged in the management and operation of Royal Park Hotels Group Hotels for the purpose described in “III Shared Use of Personal Information”. We may also share your Personal Information with internet service providers, cloud service providers, guest service providers, Most of these recipients are located in Japan. We will ensure an adequate level of protection of your Personal Information when they are disclosed to a recipient located outside the EEA and UK.<br />
                    4. Transfer of your Personal Information: Your Personal Information may be transferred to and stored by the Company and our service providers in countries outside the country in which you are located and outside the EEA and UK. The Company operates businesses in multiple jurisdictions, some of which are not located in the EEA and UK, such as Japan, China and the USA. Where we transfer your Personal Information outside the EEA and UK, we will ensure that:<br />
                    (a) the recipient destination has been subject to a finding from the European Commission that it ensures an adequate level of protection for the rights and freedoms that you possess in respect of your Personal Information; or<br />
                    (b) the recipient enters into standard data protection clauses with us that have been approved by the European Commission.<br />
                    You can obtain more detailed information about the protection given to your Personal Information when it is transferred outside the EEA and UK (including a copy of the standard data protection clauses which we have entered into with recipients of your Personal Information) by contacting us in accordance with the contact information provided above.<br />
                    Retention of Your Personal Information: The Company will keep your information for as long as we need it for the purpose it is being processed for. For example, where you book a stay with us we will keep the information related to your booking, so we can fulfill the specific travel arrangements you have made and after that, we will keep the information for a period which enables us to handle or respond to any complaints, queries or concerns relating to the booking. The information may also be retained so that we can continue to improve your experience with us and to ensure that you receive any loyalty rewards which are due to you.<br />
                    We will actively review the information we hold and delete it securely, or in some cases anonymize it, when there is no longer a legal, business or customer need for it to be retained.<br />
                    5. Your Data Protection Rights: Under certain circumstances prescribed by law, you have the right to:<br />
                    - Request information about whether we hold Personal Information about you, and, if so, what that information is and why we are holding and using it.<br />- Request access to your Personal Information (commonly known as a “data subject access request”). This enables you to receive a copy of the Personal Information we hold about you and to check that we are processing it lawfully.<br />
                    - Request correction of the Personal Information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.<br />
                    - Request erasure of your Personal Information. This enables you to ask us to delete or remove Personal Information where there is no basis for us to continue to process it. You also have the right to ask us to delete or remove your Personal Information where you have exercised your right to object to it being processed.<br />
                    - Request restriction of processing of your Personal Information. You may request restriction in certain circumstances. For example, you can request it when you dispute the accuracy of your Personal Information or when you want to confirm the legal grounds for the lawful processing of your Personal Information.<br />
                    - Object to the processing of your Personal Information where we are relying on a legitimate interest (or those of a third party) and you want to object to processing on this ground owing to some aspect of your particular circumstances. You also have the right to object where we are processing your Personal Information for direct marketing purposes.<br />- Object to automated decision-making including profiling. You may request not to be the subject of any automated decision-making which uses your Personal Information or profiles you.<br />- Request transfer of your Personal Information in an electronic and structured form to you or to another party (commonly known as the right to “data portability”). This enables you to receive your data from us in an electronically useable format and to transfer your data to another party in an electronically useable format.<br />- Withdraw consent. Where you have provided your consent to the collection, processing and transfer of your Personal Information for a specific purpose, you have the right to withdraw your consent for that specific processing at any time. Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law. If you withdraw this consent, in some circumstances, we will not be able to provide all or parts of the services you have requested from us and you will not be able to earn points, cancel your booking or obtain a refund of any charges you have paid.<br />
                    If you want to exercise any of these rights listed above, please send an email to privacy-data@rphs.jp<br />
                    You also have the right to lodge a complaint with the competent data protection authority if you think that any of your rights have been infringed by the Company.</p>
                    <h3 className="c_headingLv4">Ⅺ. Use of Access Data</h3>
                    <p className="u_mb20">1. The Company collects and uses the following types of access data from visitors to our websites and users of our services.<br />
                    - In some cases, the Company makes use of information known as cookies on its websites and so on for the purpose of enhancing customer convenience. You can disable cookie functionality by changing browser settings, but it may not be possible to use some services as a result. To find out how to change your cookie settings, please select “Help” from the menu bar of your browser and search for the word “cookie”.<br />
                    - Access logs. The Company may use access log information for the purpose of statistical analysis such as investigating the number of times that the Company’s website is used, but such information does not include personally identifiable information.<br />
                    2. The following third-party providers may have access to the access data collected by us.<br />
                    Google Analytics. Google Analytics sets and accesses cookies on your computer or other device to collect information such as the numbers of visitors to our websites, where visitors have come to the websites from, and the pages they have visited. Our websites use Google Analytics to help collect and analyze certain information to engage in analysis, auditing, research and reporting. You may review Google Analytics’ security and privacy principles by clicking here, and opt out of the use of cookies in web browsers by Google Analytics by clicking here.</p>
                    <p className="u_mb20">Masaya Oshima<br />
                    President and Chief Privacy Officer<br />
                    Mitsubishi Estate Hotels & Resorts Co., Ltd.</p>
                  </>
                }
              ]
            } />
          </section>
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`